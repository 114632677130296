import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const NewsLogo = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      aim: file(relativePath: { eq: "news/aim.png" }) {
        childImageSharp {
          fluid(maxWidth: 148) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cw: file(relativePath: { eq: "news/cw.png" }) {
        childImageSharp {
          fluid(maxWidth: 98) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      deccanherald: file(relativePath: { eq: "news/deccanherald.png" }) {
        childImageSharp {
          fluid(maxWidth: 132) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      erpinnews: file(relativePath: { eq: "news/erpinnews.png" }) {
        childImageSharp {
          fluid(maxWidth: 304) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estrade: file(relativePath: { eq: "news/estrade.png" }) {
        childImageSharp {
          fluid(maxWidth: 228) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      etrise: file(relativePath: { eq: "news/etrise.png" }) {
        childImageSharp {
          fluid(maxWidth: 276) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      financialexpress: file(
        relativePath: { eq: "news/financialexpress.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 182) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      firstpost: file(relativePath: { eq: "news/firstpost.png" }) {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ibtimes: file(relativePath: { eq: "news/ibtimes.png" }) {
        childImageSharp {
          fluid(maxWidth: 212) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      inc42: file(relativePath: { eq: "news/inc42.png" }) {
        childImageSharp {
          fluid(maxWidth: 158) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iotindia: file(relativePath: { eq: "news/iotindia.png" }) {
        childImageSharp {
          fluid(maxWidth: 188) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itvarnews: file(relativePath: { eq: "news/itvarnews.png" }) {
        childImageSharp {
          fluid(maxWidth: 148) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ndtv: file(relativePath: { eq: "news/ndtv.png" }) {
        childImageSharp {
          fluid(maxWidth: 212) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pcquest: file(relativePath: { eq: "news/pcquest.png" }) {
        childImageSharp {
          fluid(maxWidth: 113) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      peoplematters: file(relativePath: { eq: "news/peoplematters.png" }) {
        childImageSharp {
          fluid(maxWidth: 83) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pressreader: file(relativePath: { eq: "news/pressreader.png" }) {
        childImageSharp {
          fluid(maxWidth: 332) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      qrius: file(relativePath: { eq: "news/qrius.png" }) {
        childImageSharp {
          fluid(maxWidth: 238) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      siliconindia: file(relativePath: { eq: "news/siliconindia.png" }) {
        childImageSharp {
          fluid(maxWidth: 286) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      startupsjourney: file(relativePath: { eq: "news/startupsjourney.png" }) {
        childImageSharp {
          fluid(maxWidth: 144) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      techcircle: file(relativePath: { eq: "news/techcircle.png" }) {
        childImageSharp {
          fluid(maxWidth: 252) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      technuter: file(relativePath: { eq: "news/technuter.png" }) {
        childImageSharp {
          fluid(maxWidth: 74) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      techobserver: file(relativePath: { eq: "news/techobserver.png" }) {
        childImageSharp {
          fluid(maxWidth: 194) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      telecom: file(relativePath: { eq: "news/telecom.png" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thedatadriver: file(relativePath: { eq: "news/thedatadriver.png" }) {
        childImageSharp {
          fluid(maxWidth: 170) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wiredfocus: file(relativePath: { eq: "news/wiredfocus.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yourstory: file(relativePath: { eq: "news/yourstory.png" }) {
        childImageSharp {
          fluid(maxWidth: 190) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt}
      imgStyle={{ objectFit: "contain" }}
    />
  )
}

export default NewsLogo
