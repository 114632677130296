import React, { Component } from "react"
import { Slide, Fade } from "react-slideshow-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import "../scss/home.scss"
import LandingImg from "../components/landing-img"
import NewsSlideImg from "../components/news-slide-img"
import SolLogo from "../components/solution-logo"
import ClientLogo from "../components/client-logo"
import PartnerLogo from "../components/partner-logo"
import NewsLogo from "../components/news-logo"
import VisionImg from "../components/vision-img"
import { setDefaultHeaderTheme } from "../utils/common-util"
import {
  getVisions,
  getProducts,
  getClients,
  getPartners,
  getNews,
  getMedias,
  getBlogs,
} from "../data/home"
import homePageVideo0 from "../video/home-page-0.mp4"
import poster from "../svgs/spinner.svg"

const mediaSliderProps = {
  duration: 10000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: false,
}

const blogSliderProps = {
  duration: 20000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: false,
}

const whRatio = 1.7777

// const homeVideos = [
//     homePageVideo1,
//     homePageVideo0
// ];

class Home extends Component {
  constructor(props) {
    super(props)

    this.partnerCont = React.createRef()
    this.wrinCont = React.createRef()
    this.newsFaderef = React.createRef()
    this.landingVideo = React.createRef()
    this.visionCardsRef = React.createRef()
    this.visionCardsPos = 0
    this.newsSliderProps = {
      duration: 10000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
      onChange: this.onNewsSlideChange.bind(this),
    }
    this.newsFadeProps = {
      transitionDuration: 400,
      infinite: false,
      indicators: false,
      autoplay: false,
      arrows: false,
    }
    this.visionSlideScrollWidth = 0
    this.landingImgsFadeProp = {
      duration: 3000,
      transitionDuration: 400,
      infinite: true,
      indicators: false,
      arrows: false,
    }
    this.activeVideoIdx = 0

    this.state = {
      newsFadeImgs: {},
      activeVideo: homePageVideo0,
    }
  }

  onNewsSlideChange = (oldIndex, newIndex) => {
    this.newsFaderef.current.goTo(newIndex)
  }

  adjustVideoWidthHeight = () => {
    let widthToBe = window.innerHeight * whRatio
    if (window.innerWidth <= widthToBe) {
      this.landingVideo.current.style.height = window.innerHeight + "px"
      this.landingVideo.current.style.width = widthToBe.toFixed(1) + "px"
      let diff = window.innerWidth - widthToBe
      this.landingVideo.current.style.marginLeft = (diff / 2).toFixed(1) + "px"
    } else {
      this.landingVideo.current.style.width = window.innerWidth + "px"
      let heightToBe = window.innerWidth / whRatio
      this.landingVideo.current.style.height = heightToBe.toFixed(1) + "px"
      let diff = window.innerHeight - heightToBe
      this.landingVideo.current.style.marginTop = (diff / 2).toFixed(1) + "px"
    }
  }

  visionSlideMove = d => {
    let max = this.visionCardsRef.current.clientWidth - window.innerWidth
    let now = this.visionCardsPos + d * this.visionSlideScrollWidth
    if (d < 0) {
      if (max < Math.abs(now) + 150) {
        now = d * max
      } else if (max < Math.abs(now)) {
        now = d * max
      }
    } else if (d > 0) {
      if (now + 150 > 0) {
        now = 0
      } else if (now > 0) {
        now = 0
      }
    }
    if (this.visionCardsPos !== now) {
      this.visionCardsRef.current.style.transform =
        "translateX(" + now.toFixed(1) + "px)"
      this.visionCardsPos = now
    }
  }

  // switchVideo = () => {
  //     this.activeVideoIdx = ++(this.activeVideoIdx) % homeVideos.length;
  //     this.setState({
  //         activeVideo: homeVideos[this.activeVideoIdx]
  //     }, () => {
  //         this.landingVideo.current.play();
  //     });
  // };

  componentDidMount() {
    setDefaultHeaderTheme()

    this.visionSlideScrollWidth = window.innerWidth * 0.26 + 20

    let height = this.wrinCont.current.clientHeight / 2
    this.partnerCont.current.style.paddingBottom =
      parseFloat(height).toFixed(1) + "px"
    this.partnerCont.current.style.marginBottom =
      parseFloat(height).toFixed(1) + "px"

    this.adjustVideoWidthHeight()
    // this.setState({
    //     newsFadeImgs: {
    //         entrepreneur: getNewsSlideImg('entrepreneur', this.wrinCont.current.clientWidth/2, this.wrinCont.current.clientHeight),
    //         analyticsindiamag: getNewsSlideImg('analyticsindiamag', this.wrinCont.current.clientWidth/2, this.wrinCont.current.clientHeight)
    //     }
    // });
  }

  render() {
    const visions = getVisions()
    const products = getProducts()
    const clients = getClients()
    const partners = getPartners()
    const news = getNews()
    const medias = getMedias()
    const blogs = getBlogs()

    return (
      <>
        <div className="hm-top-content iww-full-height fixed">
          <video
            ref={this.landingVideo}
            src={this.state.activeVideo}
            crossOrigin="anonymous"
            /*onEnded={this.switchVideo}*/ preload="auto"
            poster={poster}
            loop
            autoPlay
            muted
          >
            {/* <source  type="video/mp4" /> */}
            Your browser does not support the video tag...
          </video>
          <div className="landing-fade-wrap">
            <Fade {...this.landingImgsFadeProp}>
              {/* <div className="each-fade">
                            <LandingImg classes="home-img wwd-img" name="whatwedo"></LandingImg>
                        </div> */}
              <div className="each-fade">
                <LandingImg
                  classes="home-img ds-img"
                  name="datasource"
                ></LandingImg>
              </div>
              <div className="each-fade">
                <LandingImg
                  classes="home-img fe-img"
                  name="frontend"
                ></LandingImg>
              </div>
            </Fade>
          </div>
        </div>
        <div className="industries">
          <h1 className="industries-text">
            Enterprise AI Platform for CCTVs
          </h1>
          <div className="vision-cards-wrap">
            <button
              className="button prev"
              onClick={this.visionSlideMove.bind(this, 1)}
            >
              <span className="icon is-small">
                <i className="fas fa-angle-left"></i>
              </span>
            </button>
            <div
              className="vision-cards"
              ref={this.visionCardsRef}
              style={{
                width:
                  "calc(" +
                  visions.length * 26 +
                  "% - " +
                  (visions.length - 1) * 20 +
                  "px + 8vw)",
              }}
            >
              {visions.map(vs => {
                return (
                  <div key={vs.key} className="vision-card">
                    <div className="vision-img">
                      <VisionImg name={vs.key}></VisionImg>
                    </div>
                    <AniLink
                      className="vision-heading"
                      to="/iris/"
                      state={{ section: vs.key + "-row" }}
                    >
                      <span className="vh-text">{vs.label}</span>
                      <button className="button">
                        <span className="icon is-small">
                          <i className="fas fa-long-arrow-alt-right"></i>
                        </span>
                      </button>
                    </AniLink>
                    <div className="vision-desc">{vs.desc}</div>
                  </div>
                )
              })}
            </div>
            <button
              className="button next"
              onClick={this.visionSlideMove.bind(this, -1)}
            >
              <span className="icon is-small">
                <i className="fas fa-angle-right"></i>
              </span>
            </button>
          </div>
        </div>
        <div className="solutions-content iww-container">
          <div className="sol-heading">Our Products</div>
          <div className="solutions">
            {products.map(prd => {
              return (
                <div key={prd.key} className="solution">
                  <div className="sol-icon iris-icon">
                    <SolLogo name={prd.icon} alt={prd.alt}></SolLogo>
                  </div>
                  <div className="sol-title">{prd.title}</div>
                  <div className="sol-desc">{prd.desc}</div>
                  <AniLink
                    className="button is-primary is-rounded is-small"
                    to={prd.to}
                  >
                    Know more
                  </AniLink>
                </div>
              )
            })}
          </div>
        </div>
        <div className="client iww-container">
          <div className="our-client-title">Our Customers</div>
          <div className="client-tiles">
            {clients.map(cl => {
              return (
                <div
                  key={cl.key}
                  className={cl.size ? "client-tile " + cl.size : "client-tile"}
                >
                  <ClientLogo name={cl.key} alt={cl.alt}></ClientLogo>
                </div>
              )
            })}
          </div>
        </div>
        <div ref={this.partnerCont} className="partners-content iww-container">
          <div className="partners-detail">
            <div className="partners-info">
              <div className="partner-title">
                Technology Collaborations
                <span className="right-line"></span>
              </div>
              <div className="partner-desc">
                Proud to be associated with some of the industry defining
                organizations.
              </div>
            </div>
            <div className="partners-logo">
              {partners.map(pt => {
                return (
                  <div
                    key={pt.key}
                    className={pt.size ? "partner " + pt.size : "partner"}
                  >
                    <PartnerLogo partner={pt.key} alt={pt.alt}></PartnerLogo>
                  </div>
                )
              })}
            </div>
          </div>
          <div ref={this.wrinCont} className="we-are-in-news">
            <div className="wrin-img">
              <Fade {...this.newsFadeProps} ref={this.newsFaderef}>
                {news.map(nw => {
                  return (
                    <div key={nw.key} className="each-fade">
                      <NewsSlideImg name={nw.key} alt={nw.alt}></NewsSlideImg>
                    </div>
                  )
                })}
              </Fade>
            </div>
            <div className="wrin-wrap">
              <div className="wrin-title">We're in the news</div>
              <Slide {...this.newsSliderProps}>
                {news.map(nw => {
                  return (
                    <div key={nw.key} className="each-slide">
                      <div className="wrin-heading">{nw.head}</div>
                      <div className="wrin-desc">{nw.desc}</div>
                      <div className="wrin-more">
                        <OutboundLink
                          href={nw.href}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <span>Read all about it here</span>
                          <span className="more-icn">
                            <i className="fas fa-angle-double-right faa-fast faa-passing animated"></i>
                          </span>
                        </OutboundLink>
                      </div>
                    </div>
                  )
                })}
              </Slide>
            </div>
          </div>
        </div>
        <div className="newsworthy iww-container">
          <div className="newsworthy-title">Media Mentions & Awards</div>
          <div className="news-tiles">
            <Slide {...mediaSliderProps}>
              {medias.map((row, i) => {
                return (
                  <div key={"media-" + i} className="each-slide">
                    <div className="columns">
                      {row.map(md => {
                        return (
                          <div key={md.key} className="column">
                            <NewsLogo name={md.key} alt={md.alt}></NewsLogo>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </Slide>
          </div>
        </div>
        <div className="blogs iww-container">
          <div className="blogs-header">
            <span>Latest Blogs</span>
            <span className="right-line"></span>
          </div>
          <Slide {...blogSliderProps}>
            {blogs.map((row, i) => {
              return (
                <div key={"blog-row-" + i} className="each-slide">
                  <div className="columns">
                    <div className="column">
                      <OutboundLink
                        href={row.href1}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="blog-title"
                      >
                        {row.title1}
                      </OutboundLink>
                      <div className="blog-desc">{row.desc1}</div>
                      <OutboundLink
                        href={row.href1}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="button is-hovered is-small"
                      >
                        Read more
                      </OutboundLink>
                    </div>
                    <div className="column is-2"></div>
                    <div className="column">
                      {row.href2 ? (
                        <>
                          <OutboundLink
                            href={row.href2}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="blog-title"
                          >
                            {row.title2}
                          </OutboundLink>
                          <div className="blog-desc">{row.desc2}</div>
                          <OutboundLink
                            href={row.href2}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="button is-hovered is-small"
                          >
                            Read more
                          </OutboundLink>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </Slide>
        </div>
      </>
    )
  }
}

export default Home
