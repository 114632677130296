import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const NewsSlideImg = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      analyticsindiamag: file(
        relativePath: { eq: "news-slides/analyticsindiamag.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2072) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      entrepreneur: file(relativePath: { eq: "news-slides/entrepreneur.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2072) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dqindia: file(relativePath: { eq: "news-slides/dqindia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1716) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bangaloreinsider: file(
        relativePath: { eq: "news-slides/bangaloreinsider.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1596) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      navbharattimes: file(
        relativePath: { eq: "news-slides/navbharattimes.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1704) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      expresscomputer: file(
        relativePath: { eq: "news-slides/expresscomputer.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      theweek: file(relativePath: { eq: "news-slides/theweek.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1574) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt || ""}
      imgStyle={{ objectFit: "contain" }}
    />
  )
}

export default NewsSlideImg
