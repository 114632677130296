import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const VisionImg = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      RETAIL: file(relativePath: { eq: "visions/retail.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 604) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      PETROLEUMRETAIL: file(relativePath: { eq: "visions/petroleum.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 604) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HEALTHSAFETY: file(relativePath: { eq: "visions/health-safety.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 604) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      WAREHOUSES: file(relativePath: { eq: "visions/warehouse.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 596) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      SECURITY: file(relativePath: { eq: "visions/security.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 604) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ATM: file(relativePath: { eq: "visions/atm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 604) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt || ""}
      imgStyle={{ objectFit: "contain" }}
    />
  )
}

export default VisionImg
