import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ClientLogo = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      assurant: file(relativePath: { eq: "clients/assurant.png" }) {
        childImageSharp {
          fluid(maxWidth: 475) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bajajMotorcycles: file(
        relativePath: { eq: "clients/bajaj-motorcycles.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      canyonTech: file(relativePath: { eq: "clients/canyon-tech.png" }) {
        childImageSharp {
          fluid(maxWidth: 418) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      godrej: file(relativePath: { eq: "clients/godrej.png" }) {
        childImageSharp {
          fluid(maxWidth: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      larsen: file(relativePath: { eq: "clients/larsen.png" }) {
        childImageSharp {
          fluid(maxWidth: 375) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mondelez: file(relativePath: { eq: "clients/mondelez.png" }) {
        childImageSharp {
          fluid(maxWidth: 412) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      novoNordisk: file(relativePath: { eq: "clients/novo-nordisk.png" }) {
        childImageSharp {
          fluid(maxWidth: 195) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pegasystemsInc: file(
        relativePath: { eq: "clients/pegasystems-inc.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      postnord: file(relativePath: { eq: "clients/postnord.png" }) {
        childImageSharp {
          fluid(maxWidth: 520) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      syngenta: file(relativePath: { eq: "clients/syngenta.png" }) {
        childImageSharp {
          fluid(maxWidth: 378) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tataPowerSolar: file(
        relativePath: { eq: "clients/tata-power-solar.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 334) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      johnsonControls: file(
        relativePath: { eq: "clients/johnson-controls.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      xerox: file(relativePath: { eq: "clients/xerox.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heineken: file(relativePath: { eq: "clients/heineken.png" }) {
        childImageSharp {
          fluid(maxWidth: 375) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      novaspect: file(relativePath: { eq: "clients/novaspect.png" }) {
        childImageSharp {
          fluid(maxWidth: 281) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt}
      imgStyle={{ objectFit: "contain" }}
    />
  )
}

export default ClientLogo
