import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LandingImg = ({ name, alt, classes }) => {
  const data = useStaticQuery(graphql`
    query {
      datasource: file(relativePath: { eq: "landing/datasource.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2091) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      frontend: file(relativePath: { eq: "landing/frontend.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2732) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      whatwedo: file(relativePath: { eq: "landing/whatwedo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2548) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={classes}>
      <Img
        fluid={data[name].childImageSharp.fluid}
        alt={alt || ""}
        imgStyle={{ objectFit: "contain" }}
      />
    </div>
  )
}

export default LandingImg
