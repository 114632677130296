import retail from "../video/iris/retail.mp4"
import petroleum_pstr from "../images/iris/petroleum-poster.jpg"
import healthSafety from "../video/iris/health-safety.mp4"
import warehouses from "../video/iris/warehouses.mp4"
import security from "../video/iris/security.mp4"
import atm from "../video/iris/atm.mp4"

const visions = [
  {
    key: "RETAIL",
    label: "retail",
    desc:
      "Retail outlets are mostly equipped with CCTV Cameras. IRIS enables you to tap into them to get meaningful insights in your customer behaviour, staff utilization, and store layout. You can use your existing infrastructure to understand the footfall, customer demographics, sentiments, staff interaction and peak times.",
    alt: "Retail's Image",
    detail: `
      Leading vision AI to provide various insights about shopper, employee, assets, or even activity within the store premises. Retailers can unlock actionable insights for various aspects of their organisation - marketing, sales, customer experience or employee satisfaction using computer vision.
  
      It can generate reports on footfall in the store, demographic details such as male vs female or age, repeat customer alerts, the percentage of customers walking out without shopping, heat maps on areas most visited by customers, peak time walk-ins, the average time spent by shoppers, as well as other insights such as identifying stock-outs in real-time and alerting the manager, observing staff behavior, sentiment analysis and more. The analytical information lets retailers optimize their operations to ensure customer satisfaction.
    `,
    swipe: true,
    video: retail,
  },
  {
    key: "PETROLEUMRETAIL",
    label: "petroleum retail",
    desc:
      "The success of a petroleum retail outlet is predominantly dependent on its ability to establish a core group of repeat users. IRIS utilises existing CCTV cameras in a petrol bunk to identify customers and their vehicle related analytical information, while also optimising adherence to standard operating procedures.",
    alt: "Petroleum Retail's Image",
    detail: `
      Petroleum retailers can now leverage data analytics and drive towards data driven goals for various aspects of their organisation.
      
      It can report customer demographics, wait time before fueling, filling time, peak time, fire detection, decantation and compliance, mobile phone usage, vehicle demographics, and vehicle count, etc.
      
      Thus, you can develop a base of regular customers, the percentage of customers who transacted at the outlet in a given time frame, the proportion of loyalty card holders, repeat usage rate, etc. This enables big retail petroleum chains to understand the estimated contribution of the new petrol station to the chain’s total volume. Many more such insights can be explored depending on the requirements.
    `,
    swipe: false,
    video: null,
    poster: petroleum_pstr,
  },
  {
    key: "HEALTHSAFETY",
    label: "health & safety",
    desc:
      "The occupational health and safety in industrial premises is predominantly people-dependent, rendering it vulnerable to human error. From detecting fire to PPE, it sends alerts on detecting non-compliance and can do so much more. Moreover, it generates daily reports on machine utilization demonstrating their usage efficiency.",
    alt: "Health and Safety's Image",
    detail: `
      It is a common concern among various sectors - be it manufacturing, construction, energy, retail or any other.
      
      Organizations can benefit by harnessing the power of computer vision AI. From detecting a fire in under 10 seconds, to monitoring usage of PPE to prevent accidents and send alerts in case of non-compliance, IRIS can do it all. Moreover, it can monitor hazard signs, use of correct equipment, proper lighting, overcrowding, monitoring entry exit into exclusion zones, and prevention of health injuries, thereby complying a safe work environment.
      
      In addition, IRIS generates reports on predictive maintenance, usage efficiency of the various types of machines including productive time, non-productive time, maintenance, etc.
    `,
    swipe: true,
    video: healthSafety,
  },
  {
    key: "WAREHOUSES",
    label: "warehouses",
    desc:
      "IRIS can help you measure low-level details like truck TAT, Dock utilization, Forklift, MHE and Personnel utilization while also ensuring adherence to SOP.",
    alt: "Warehouse's Image",
    detail: `
      Modernise your warehouse operations by exploring the potential of computer vision and machine learning.
      
      Asset utilization, Dock utilization, Truck turn-around-time (TAT), Machine utilization are some of the factors that affect the operational efficiency and costs. There was a gap in asset utilization (like unloaded/loaded forklift movement), space utilization, TAT, etc. Furthermore, there were accidents due to near misses, which could have been prevented if the near misses would have been reported timely.
      
      In an IRIS enabled warehouse, virtual boundaries separate the pathways for forklifts & pedestrians and real-time alarms are raised if these are not followed, near-misses are monitored based on predefined rules. Moreover, it generates reports on the different types of loads, and more.
    `,
    swipe: false,
    video: warehouses,
  },
  {
    key: "SECURITY",
    label: "security",
    desc:
      "We aim to make the intrusion panel redundant. Our genre defining algorithm converts a regular CCTV camera into a dependable security solution, which detects movement from as far as 120m and identifies human intrusion against other movements.",
    alt: "Security's Image",
    detail: `
      For seamless operation in any facility, it  is essential to secure the premises from pilferage and ensure employee safety. Most of the manufacturing premises, warehouses, offices, petrol stations, and even large open farms are monitored using CCTV cameras. Companies want to control intrusion, detect fire, generate alarms, prevent accidents, and manage their workplace security & safety.
      
      From identifying dangerous objects like knives and guns to raising alarms for the same, IRIS is the go-to solution. It can also be used in high-security environments like banks where they can use AI computer vision for more accurate identification of customers when large amounts of money are being exchanged.
    `,
    swipe: true,
    video: security,
  },
  {
    key: "ATM",
    label: "ATM",
    desc:
      "ATMs are a very important part of the banking industry. With IRIS, banks can ensure security inside ATM's by detecting suspicious activities like a person wearing helmet, using mobile phones or attempting to tamper with the ATM or Cameras.",
    alt: "ATM's Image",
    detail: `
      One of the upcoming areas for AI vision is bank ATMs. One of the reasons for this is because banks usually operate a huge network of ATMs, some of which might be in remote locations. In general, an ATM breakdown potentially causes a lot of inconvenience to customers. Thus, IRIS can be utilised for predictive maintenance as well as forecasting ATM cash demand.
      
      Likewise, banks can get crucial insights such as the presence of security guard, peak times, average time spent, demographics, monitor back room, monitor user-posture to prevent theft and raise alert, detect fire. This could also help them get insights on the actual usage and efficiency of their ATMs.
    `,
    swipe: false,
    video: atm,
  },
]

const products = [
  {
    key: "IRIS",
    icon: "iris",
    alt: "IRIS's Logo",
    title: "Computer Vision Solution",
    desc:
      "Transform CCTV Cameras to analytical assets that provide actionable insights.",
    to: "/iris/",
  },
  {
    key: "SILVERLINE",
    icon: "sliverline",
    alt: "Silverline's Logo",
    title: "Enterprise Mobility Platform",
    desc:
      "A cloud based platform for enterprise mobile applications that runs across devices.",
    to: "/silverline/",
  },
  {
    key: "SILVERLINEMDM",
    icon: "mdm",
    alt: "Silverline MDM's Logo",
    title: "Mobile Device Management",
    desc:
      "Approved by Google, it offers effortless management of mobile devices remotely.",
    to: "/silverlinemdm/",
  },
]

const clients = [
  {
    key: "assurant",
    alt: "Assurant's Logo",
  },
  {
    key: "bajajMotorcycles",
    alt: "Bajaj Motorcycles's Logo",
  },
  {
    key: "canyonTech",
    alt: "Canyon Tech's Logo",
  },
  {
    key: "godrej",
    alt: "Godrej's Logo",
    size: "large",
  },
  {
    key: "larsen",
    alt: "Larsen's Logo",
    size: "large",
  },
  {
    key: "mondelez",
    alt: "Mondelez's Logo",
    size: "medium",
  },
  {
    key: "novoNordisk",
    alt: "Novo Nordisk's Logo",
    size: "x-large",
  },
  {
    key: "pegasystemsInc",
    alt: "Pegasystems Inc's Logo",
  },
  {
    key: "postnord",
    alt: "Postnord's Logo",
    size: "small",
  },
  {
    key: "syngenta",
    alt: "Syngenta's Logo",
  },
  {
    key: "tataPowerSolar",
    alt: "Tata Power Solar's Logo",
    size: "large",
  },
  {
    key: "johnsonControls",
    alt: "Johnson Controls's Logo",
    size: "large",
  },
  {
    key: "xerox",
    alt: "Xerox's Logo",
    size: "medium",
  },
  {
    key: "heineken",
    alt: "Heineken's Logo",
    size: "x-large",
  },
  {
    key: "novaspect",
    alt: "Novaspect Inc's Logo",
    size: "large",
  },
]

const partners = [
  {
    key: "aws",
    alt: "Amazon Web Service's Logo",
    size: "large",
  },
  {
    key: "nvidia",
    alt: "Nvidia's Logo",
    size: "large",
  },

  {
    key: "pega",
    alt: "Pega's Logo",
  },
  {
    key: "datema",
    alt: "Datema's Logo",
    size: "medium",
  },
  {
    key: "canyonTech",
    alt: "Canyon Tech's Logo",
    size: "medium",
  },
  {
    key: "assert",
    alt: "Assert's Logo",
  },
  {
    key: "microsoft",
    alt: "Microsoft's Logo",
    size: "large",
  },
  {
    key: "soitron",
    alt: "Soitron's Logo",
    size: "large",
  },
]

const news = [
  {
    key: "dqindia",
    alt: "dqindia.com",
    head:
      "IRIS: An AI based computer vision platform that helps organisations derive actionable insights using their existing CCTV infrastructure",
    desc:
      "Integration Wizards Solutions is at the forefront of innovation. It believes in the transformation and keeping up with the latest technological developments. The company provides the IRIS enterprise computer vision solution, Silverline, a one-stop enterprise mobility platform, and Silverline MDM that helps experience the future of device management.",
    href:
      "https://www.dqindia.com/future-ai-indian-global-ecosystems-promising-kunal-kislay-integration-wizards",
  },
  {
    key: "bangaloreinsider",
    alt: "bangaloreinsider.com",
    head:
      "Future of AI in Indian and global ecosystems is promising: Kunal Kislay, Integration Wizards",
    desc:
      "The entrepreneurs of the world are into a class of their own. Investors trust them with their money for future returns and upending the status quo. Employees trust them with their time and effort, in order to raise a paycheck every month.",
    href:
      "https://www.bangaloreinsider.com/iris-an-ai-based-computer-vision-platform-that-helps-organisations-derive-actionable-insights-using-their-existing-cctv-infrastructure",
  },
  {
    key: "navbharattimes",
    alt: "navbharattimes.indiatimes.com",
    head:
      "Special CCTV technology will protect from Corona, those who do not apply masks will be caught",
    desc:
      "Somewhere people are following social distancing or putting on masks, CCTV cameras can now be used to monitor it themselves. Of course this may sound shocking, but Bangalore based company Integration Wizards Solutions has suggested a way to take the help of Artificial Intelligence (AI) for this. The company works as an AI-based computer vision platform, and in view of the situation created during the corona virus, they started work on a special tech IRIS AI.",
    href:
      "https://www.dqindia.com/future-ai-indian-global-ecosystems-promising-kunal-kislay-integration-wizards",
  },
  {
    key: "expresscomputer",
    alt: "expresscomputer.in",
    head:
      "Trailblazing technologies show the way for India Inc to restart operations, safely",
    desc:
      "The world is now united and on a mission to fight the unpropitious times that lie ahead due to COVID-19. The pandemic has exposed the hollowness of the healthcare system and has left the economy in dire straits. A rising economic depression is affecting almost all sectors ranging from manufacturing to trade, transport, tourism, retail, and so on. Nothing is unscathed from this unanticipated situation as it touches our deeper vulnerabilities.",
    href:
      "https://www.expresscomputer.in/guest-blogs/trailblazing-technologies-show-the-way-for-india-inc-to-restart-operations-safely/57811",
  },
  {
    key: "theweek",
    alt: "theweek.in",
    head: "Much at stake",
    desc:
      "It is likely that India could target Chinese telecom infra giants, which could bring it closer to the US,” said Kunal Kislay, cofounder and CEO of Integration Wizards Solution, an Indian AI firm. “In the short term, this will have a disrupting effect on our existing telecom players, who have significant investments in technologies provided by these organisations.",
    href: "https://www.theweek.in/theweek/cover/2020/07/02/much-at-stake.html",
  },
  // {
  //   key: 'jagran',
  //   alt: 'jagran.com',
  //   head: 'The threat of privacy from Chinese companies\' apps or solutions: Kunal Kisalaya',
  //   desc: 'The risk of breach of privacy has increased with apps or solutions created by Chinese companies. In such a situation, along with the government, common people and many entrepreneurs are giving preference to Made in India. Kunal Kisalaya, CEO of Integration Wizards Solution, said that we do not depend on China for any solution using artificial intelligence. Therefore, the boycott of China will not affect the industry.',
  //   href: 'https://m.jagran.com/technology/tech-news-the-threat-of-privacy-through-apps-or-solutions-created-by-chinese-companies-20455599.html'
  // },
  {
    key: "entrepreneur",
    alt: "entrepreneur.com",
    head: "Building Deep Tech In India: How This Start-Up Cracked The Code",
    desc:
      "The Bengaluru-based company, founded in 2014, started with enterprise mobility, creating a platform that would allow for seamless communication across devices. It now has operations in the US as well and expects to record revenue of $3 million this year.",
    href: "https://www.entrepreneur.com/article/344190",
  },
  {
    key: "analyticsindiamag",
    alt: "analyticsindiamag.com",
    head:
      "HOW THIS 5-YEAR-OLD BOOTSTRAPPED STARTUP IS HELPING 20,000 ENTERPRISE USERS GAIN ACTIONABLE INTELLIGENCE",
    desc:
      "Bangalore-based AI startup called Integration Wizards is utilising artificial intelligence and machine learning to help enterprises determine actionable intelligence from images, videos, and live data.",
    href:
      "https://analyticsindiamag.com/how-this-5-year-old-bootstrapped-startup-is-helping-20000-enterprises-gain-actionable-intelligence/",
  },
]

const medias = [
  [
    {
      key: "aim",
      alt: "Aim's Logo",
    },
    {
      key: "cw",
      alt: "CW's Logo",
    },
    {
      key: "deccanherald",
      alt: "Deccan Herald's Logo",
    },
    {
      key: "erpinnews",
      alt: "ERP In News's Logo",
    },
    {
      key: "estrade",
      alt: "ES Trade's Logo",
    },
  ],
  [
    {
      key: "etrise",
      alt: "ET Rise's Logo",
    },
    {
      key: "financialexpress",
      alt: "Financial Express's Logo",
    },
    {
      key: "firstpost",
      alt: "First Post's Logo",
    },
    {
      key: "ibtimes",
      alt: "International Business Times's Logo",
    },
    {
      key: "inc42",
      alt: "Inc 42's Logo",
    },
  ],
  [
    {
      key: "iotindia",
      alt: "IoT India Megazine's Logo",
    },
    {
      key: "itvarnews",
      alt: "IT VAR News's Logo",
    },
    {
      key: "ndtv",
      alt: "NDTV's Logo",
    },
    {
      key: "pcquest",
      alt: "PC Quest's Logo",
    },
    {
      key: "peoplematters",
      alt: "People Matters's Logo",
    },
  ],
  [
    {
      key: "pressreader",
      alt: "Press Reader's Logo",
    },
    {
      key: "qrius",
      alt: "QRIUS's Logo",
    },
    {
      key: "siliconindia",
      alt: "Silicon India's Logo",
    },
    {
      key: "startupsjourney",
      alt: "Startups Journey's Logo",
    },
    {
      key: "techcircle",
      alt: "Tech Circle's Logo",
    },
  ],
  [
    {
      key: "technuter",
      alt: "Technuter's Logo",
    },
    {
      key: "techobserver",
      alt: "Tech Observer's Logo",
    },
    {
      key: "telecom",
      alt: "Telecom's Logo",
    },
    {
      key: "thedatadriver",
      alt: "The Data Driver's Logo",
    },
    {
      key: "wiredfocus",
      alt: "Wiredfocus's Logo",
    },
    {
      key: "yourstory",
      alt: "Yourstory's Logo",
    },
  ],
]

const blogs = [
  {
    href1:
      "https://iwizardsolutions.blogspot.com/2020/06/covid-19-isnt-going-away-soon-whats.html",
    title1: "Covid-19 isn’t going away soon…… What’s your plan?",
    desc1:
      "Thankfully Covid-19 seems to be on the wane…. But it will take a long time to disappear fully, and it is unlikely to be the last pandemic. This means that YOU may need to make the workplace safe for your team and perhaps your customers.",
    href2:
      "https://iwizardsolutions.blogspot.com/2020/07/reshaping-retail-industry-paradigm.html",
    title2: "Reshaping The Retail Industry Paradigm Through AI",
    desc2:
      "Data, artificial intelligence, automation, IoT, bots, machines, and digital transformation. The buzz words. Except, these are not just buzz words, these are a reflection of a change that’s happening around us and the growing opportunity for innovation.",
  },
  {
    href1:
      "https://iwizardsolutions.blogspot.com/2020/07/enhancing-workplace-health-safety-using_2.html",
    title1: "Enhancing Workplace Health & Safety Using Computer Vision",
    desc1:
      "Although health and safety at workplaces have improved over the years, yet the UK continues to have a large number of workplace accidents. The number of accidents resulting in injury, or in some cases, even death is quite high. Many of these accidents can be avoided, and AI-based computer vision can play a significant role in cutting down these accidents.",
    href2:
      "https://iwizardsolutions.blogspot.com/2020/01/moving-towards-ai-enabled-future.html",
    title2: "Moving towards an AI-enabled future",
    desc2:
      "McKinsey Global Institute claims that artificial intelligence is contributing to a transformation of society 10 times faster and at 300 times the scale, or roughly 3000 times the impact of the Industrial Revolution.",
  },
  {
    href1:
      "https://iwizardsolutions.blogspot.com/2019/11/computer-vision-enhancing-industrial.html",
    title1: "Computer Vision: Enhancing Industrial Safety with AI",
    desc1:
      "As artificial intelligence increasingly gains prominence, several sub-domains such as computer vision, machine learning, deep learning, internet of things, and analytics are some of the technologies that have propelled growth.",
  },
]

export const getVisions = () => visions
export const getProducts = () => products
export const getClients = () => clients
export const getPartners = () => partners
export const getNews = () => news
export const getMedias = () => medias
export const getBlogs = () => blogs
