import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const PartnerLogo = ({ partner, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      assert: file(relativePath: { eq: "partners/assert.png" }) {
        childImageSharp {
          fluid(maxWidth: 192) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      microsoft: file(relativePath: { eq: "partners/microsoft.png" }) {
        childImageSharp {
          fluid(maxWidth: 312) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nvidia: file(relativePath: { eq: "partners/nvidia.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pega: file(relativePath: { eq: "partners/pega.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      canyonTech: file(relativePath: { eq: "partners/canyon-tech.png" }) {
        childImageSharp {
          fluid(maxWidth: 375) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      datema: file(relativePath: { eq: "partners/datema.png" }) {
        childImageSharp {
          fluid(maxWidth: 375) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aws: file(relativePath: { eq: "partners/aws-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      soitron: file(relativePath: { eq: "partners/soitron.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data[partner].childImageSharp.fluid}
      alt={alt}
      imgStyle={{ objectFit: "contain" }}
    />
  )
}

export default PartnerLogo
